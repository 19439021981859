import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import image_eyes from "../../../../../project_images/eyes-sm.webp";
import "../Projects.scss";

const projectEyes = () => {
  return (
    <div className="App__projects-card">
      <Card className="App__projects-card-element">
        <Card.Img
          className="lazy"
          data-mdb-lazy-src={image_eyes}
          variant="top"
          src={image_eyes}
          alt="project image"
        />
        <Card.Body className="App__project-card-body">
          <Card.Title>Watcher</Card.Title>
          <Card.Text>
            AI generated art that moves its eyes to follow you. Object detection
            is handled by Tensorflow.js. I had a lot of fun working out how to
            move the eyes.
          </Card.Text>
          <div className="App__card-btn-wrapper">
            <Button
              href="https://github.com/mattwaelder/eyes/blob/main/README.md"
              target="_blank"
              variant="light"
              className="App__project-card-btn"
            >
              Read Me
            </Button>
            <Button
              href="https://tfeyes.netlify.app"
              target="_blank"
              variant="light"
              className="App__project-card-btn"
            >
              Visit Site
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
export default projectEyes;
